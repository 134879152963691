import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import _ from 'lodash'
import { getProject } from '../utils/deployment'

const withConfig = (ComposedComponent) => {
  const composed = (props) => (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          logo: file(relativePath: { eq: "logo.png"}) {
            ...fixedImage60Height
          },
          logo_carpet: file(relativePath: { eq: "logo_carpet.png"}) {
            ...fixedImage90Height
          },
          headline: file(relativePath: { eq: "mountain80.png"}) {
            ...fixedImage800
          },
          headlineCarpet: file(relativePath: { eq: "city80.png"}) {
            ...fixedImage800
          },
          allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "index" }}}) {
            edges {
              node {
                id
                htmlAst
                frontmatter {
                  defaultTitle
                  defaultDescription
                  businessName
                  siteUrl
                  city
                  state
                  phone
                  email
                  googleBusinessLink
                  yelpBusinessLink
                  trustpilotBusinessLink
                  keyServiceName
                  keyServiceNameSynonym
                  keyServiceNameSynonym2
                  keyServiceNoun
                  keyServiceActiveNoun
                  noMoreOptions {
                    text
                  }
                  businessDescription
                  featureList1Headline
                  featureList1Subtext
                  featureList1Options {
                    text
                  }
                  promoTagline
                  showIndustry
                  reviews {
                    content
                    author
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const project = getProject()
        const edges = _.get(data, 'allMarkdownRemark.edges') || []
        const carpet = _.find(edges, e => _.includes(_.get(e, 'node.frontmatter.businessName'), 'Carpet'))
        const services = _.find(edges, e => _.includes(_.get(e, 'node.frontmatter.businessName'), 'Building'))
        const { headline, headlineCarpet } = data
        let activeEdge = services
        let activeHeadline = headline
        if (project === 'CARPET') {
          activeEdge = carpet
          activeHeadline = headlineCarpet
        }
        return (
          <ComposedComponent
            {...props}
            {...(_.get(activeEdge, 'node.frontmatter') || {})}
            headline={activeHeadline}
            utah={data.utah}
            logo={data.logo}
            logo_carpet={data.logo_carpet}
            project={project}
          />
        )
      }}
    />
  )

  composed.displayName = `WithConfig(${ComposedComponent.displayName})`
  return composed
}

export default withConfig