import React from 'react'
import { rhythm } from '../utils/typography'
import { themeStyles, colors } from '../utils/theme'
import _ from 'lodash'
import Link from 'gatsby-link'

import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaUser, FaLocationArrow } from 'react-icons/fa';
import ContactForm from './ContactForm'
import withConfig from '../wrappers/withConfig'
import brazil from '../assets/brazil.png'
import mexico from '../assets/mexico.png'
import GoogleMap from './GoogleMap'


const styles = {
  iconWrapper: {
    backgroundColor: colors.brightBlue,
    height: '40px',
    width: '40px',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: rhythm(1/2),
  },
  elementWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: rhythm(1/2),
  },
  link: {
    cursor: 'pointer',
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'normal',
  },
}

class ContactInformation extends React.Component {
  render() {
    const {
      city,
      state,
      phone,
      email,
      googleBusinessLink,
    } = this.props
    return (
      <div
        css={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          margin: '0 auto',
        }}
      >
      <a href={googleBusinessLink} css={[styles.elementWrapper, styles.link]}>
        <div css={[styles.iconWrapper]}>
          <FaMapMarkerAlt color='white' size={25} />
        </div>
        <p css={{ margin: 0, padding: 0 }}>{city}{`, `}{state}</p>
      </a>
      <a href={`tel:${phone}`} css={[styles.elementWrapper, styles.link]}>
        <div css={[styles.iconWrapper]}>
          <FaPhone color='white' size={25} />
        </div>
        <p css={{ margin: 0, padding: 0 }}>{phone}</p>
      </a>
      <a href={`mailto:${email}`} css={[styles.elementWrapper, styles.link]}>
        <div css={[styles.iconWrapper]}>
          <FaEnvelope color='white' size={25} />
        </div>
        <p css={{ margin: 0, padding: 0 }}>{email}</p>
      </a>
      </div>
    )
  }
}

class SubPages extends React.Component {
  render() {
    const links = [
      'Contact',
      'Locations',
      'Careers',
    ]
    return (
      <div
        css={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          margin: '0 auto',
        }}
      >
        {_.map(links, (l, index) => {
          return (
            <Link key={index} to={"/" + l.toLowerCase()} css={[styles.elementWrapper, styles.link]}>
              <p css={{ margin: 0, padding: 0 }}>{l}</p>
              {l === 'Careers' &&
                <span>
                  <img src={brazil} css={{
                    margin: 0,
                    marginLeft: rhythm(0.5),
                    width: '30px',
                  }}
                  />
                  <img src={mexico} css={{
                    margin: 0,
                    marginLeft: rhythm(0.5),
                    width: '37px',
                  }}
                  />
                </span>
              }
            </Link>
          )
        })}
      </div>
    )
  }
}

class NAPDetails extends React.Component {
  render() {
    return (
      <div
        css={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          margin: '0 auto',
        }}
      >
        <a href={`#`} css={[styles.elementWrapper, styles.link]}>
          <div css={[styles.iconWrapper]}>
            <FaUser color='white' size={25} />
          </div>
          <p css={{ margin: 0, padding: 0 }}>Jake Hamilton</p>
        </a>
        <a href={`tel:801-706-5932`} css={[styles.elementWrapper, styles.link]}>
          <div css={[styles.iconWrapper]}>
            <FaPhone color='white' size={25} />
          </div>
          <p css={{ margin: 0, padding: 0 }}>801-706-5932</p>
        </a>
        <a href={`#`} css={[styles.elementWrapper, styles.link]}>
          <div css={[styles.iconWrapper]}>
            <FaLocationArrow color='white' size={25} />
          </div>
          <p css={{ margin: 0, padding: 0 }}>Address: 13431 S. Lone Rock Dr. Draper, UT 84020</p>
        </a>
      </div>
    )
  }
}

const Heading = ({ title }) => (
    <div css={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', color: 'white', marginTop: rhythm(3/2), marginBottom: rhythm(1)}}>
      <h1 css={{marginBottom: rhythm(1/2), color: 'white', fontWeight: '400'}}>{title}</h1>
      <div css={{ width: '100px', height: '1px', borderBottom: `4px solid ${colors.gold}`}}/>
    </div>
)

class Footer extends React.Component {
  render() {
    const {
      city,
      state,
      phone,
      email,
      googleBusinessLink,
    } = this.props
    return (
      <div>
        <div css={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: colors.brightBlue, paddingBottom: rhythm(1)}}>
          <div css={{ ...themeStyles.innerText, display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap-reverse'}}>
            <div css={{ display: 'flex', flexDirection: 'column', flex: 1, ...themeStyles.textPadding }}>
              <Heading title="Contact Details" />
              <ContactInformation {...{city, state, phone, email, googleBusinessLink}}/>
              <Heading title="Learn More" />
              <SubPages />
            </div>
            {!this.props.hideForm &&
              <div css={{display: 'flex', flexDirection: 'column', flex: 1, ...themeStyles.textPadding }}>
                <Heading title="Free Quote" />
                <ContactForm formName='Contact'/>
              </div>
            }
            <div css={{display: 'flex', flexDirection: 'column', flex: 1, ...themeStyles.textPadding }}>
                <Heading title="Site Owner" />
                <NAPDetails></NAPDetails>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default withConfig(Footer)
