import React from 'react'
import { colors, themeStyles } from '../utils/theme'
import Header from './Header'
import Footer from './NewFooter'
import MainHelmet from './MainHelmet'
import SchemaMarkup from './SchemaMarkup'

class Template extends React.Component {
  render() {
    const { location, children, hideForm } = this.props
    return (
      <div
        css={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: colors.white }}
      >
        <SchemaMarkup />
        <MainHelmet />
        <Header />
        <div css={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: 'white', ...themeStyles.contentWidth, margin: '0 auto' }}>
          {children}
        </div>
        <Footer hideForm={hideForm}/>
      </div>
    )
  }
}

export default Template
