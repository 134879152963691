import React from 'react'
import Helmet from 'react-helmet'
import withConfig from '../wrappers/withConfig'

const MainHelmet = ({
    defaultTitle,
    defaultDescription,
    title,
    description,
    keywords,
    image,
    script,
    businessName,
    siteUrl,
    yelpBusinessLink,
    trustpilotBusinessLink,
    project
}) => {
  const displayImage = image || `${siteUrl}/${project === 'CARPET' ? 'blumont_carpet_og.png' : 'blumont_og.png'}`
  const desc = description || defaultDescription
  const ti = title || defaultTitle
  const keys = keywords || 'Cleaning, Carpets, Building Cleaning, Janitorial'
  const mainScript={
    "type": "application/ld+json",
    "innerHTML": `{
      "@context": "http://schema.org",
      "@type": "Organization",
      "name": "${businessName}",
      "url": "${siteUrl}",
      "sameAs": [
        "${yelpBusinessLink}",
        "${trustpilotBusinessLink}"
      ]
    }`
  }
  return (
    <Helmet
      title={ti}
      script={[Object.assign(mainScript, script)]}
    >
      <meta name="image" property="image" content={displayImage} />
      <meta name="keywords" property="keywords" content={keys} />
      <meta name="og:keywords" property="og:keywords" content={keys} />
      <meta name="twitter:keywords" property="twitter:keywords" content={keys} />
      <meta name="description"  property="description" content={desc} />
      <meta name="og:description" property="og:description" content={desc} />
      <meta name="twitter:description" property="twitter:description" content={desc} />
      <meta name="title" property="title" content={ti} />
      <meta name="og:title" property="og:title" content={ti} />
      <meta name="twitter:title" property="twitter:title" content={ti} />
      <meta name="og:image" property="og:image" content={displayImage} />
      <meta name="og:image" property="og:image" content={displayImage} />
      <meta
        name="twitter:image"
        property="twitter:image"
        content={displayImage}
      />
    </Helmet>
  )
}

export default withConfig(MainHelmet)
