import { rhythm } from '../utils/typography'

export const colors = {
  darkGrey: "#484848",
  lightGrey: '#F2F6F6',
  mediumGrey: '#969fa8',
  lightestGrey: '#F2F6F6',
  brightBlue: '#0035b2',
  gold: '#7f5900',
  lightGold: '#b27d00',
  white: 'white',
}

export const presets = {
  Mobile: '@media (min-width: 400px)',
  Phablet: '@media (min-width: 550px)',
  Tablet: '@media (min-width: 750px)',
  Desktop: '@media (min-width: 1000px)',
  Hd: '@media (min-width: 1200px)'
}

export const metrics = {
  defaultMargin: rhythm(2),
  defaultPadding: rhythm(3),
  defaultMobilePadding: rhythm(1),
  contentWidth: "1400px"
}

export const themeStyles = {
  blueText: { color: colors.brightBlue},
  flexRowCenter: {display: 'flex', alignItems:'center', justifyContent:'center', flexDirection:'row'},
  contentWidth: { width: "100%", maxWidth: metrics.contentWidth, margin: '0 auto'},
  blueTitleText: { color: colors.brightBlue, fontSize: rhythm(1.5), fontWeight: '400', textAlign: 'center' },
  shadow: { boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.61)'},
  elementMargin: {
    marginTop: rhythm(2),
  },
  innerTextBreak: { width: '100%', maxWidth: '700px', padding: '0 10px', margin: '0 auto' },
  horizontalTextPadding: {
    paddingLeft: metrics.defaultMobilePadding,
    paddingRight: metrics.defaultMobilePadding,
    [presets.Tablet]: {
      paddingLeft: metrics.defaultPadding,
      paddingRight: metrics.defaultPadding,
    }
  },
  textPadding: {
    paddingLeft: metrics.defaultMobilePadding,
    paddingRight: metrics.defaultMobilePadding,
    paddingTop: rhythm(1),
    paddingBottom: rhythm(1),
    [presets.Tablet]: {
      paddingLeft: metrics.defaultPadding,
      paddingRight: metrics.defaultPadding,
      paddingTop: rhythm(1),
      paddingBottom: rhythm(1),
    },
  },
  smallButton: {
    fontSize: "20px",
    color:"white",
    width:"auto",
    height:"20px",
    borderRadius: "10px",
    backgroundColor: "#805900",
    textDecoration: 'none',
    padding:"8px 20px 33px 20px"
  }
}